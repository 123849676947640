jQuery(document).ready(function ($) {
    // Specimen Logic
    const $specimen = $('.type-specimen');
    const $glyph = $specimen.find('.grid').find('div');
    const $glyph_normal_lead = $('.type-specimen .lead-normal');
    const $glyph_extra_lead = $('.type-specimen .lead-xtra');
    let $letter = '';
    let $glyph_type = '';

    $glyph.mouseover(function(){
        $glyph_type = $(this).attr('data-type');
        if($glyph_type === 'inclusiva'){
            $glyph_normal_lead.show();
            $glyph_extra_lead.hide();
            $(this).addClass('active');
            $letter = $(this).attr('data-letter');
            $glyph_normal_lead.attr('data-preview', $letter);
        }
        else{
            $glyph_normal_lead.hide();
            $glyph_extra_lead.show();
            $(this).addClass('active');
            $letter = $(this).attr('data-letter');
            $glyph_extra_lead.find('span').attr('class', $letter);
            if($(this).attr('data-type') === 'inclusiva_xtra normal'){
                $glyph_extra_lead.addClass('normal');
            }
        }
    })
    $glyph.mouseleave(function(){
        $(this).removeClass('active', 'normal');
        $glyph_extra_lead.removeClass('normal');
    })

});