// Global
jQuery(document).ready(function ($) {
// Type UI
    const result = $('.type-ui__result')
    result.click(function(){
        $(this).attr('contenteditable','true');
    })
    result.click(function(event){
        event.stopPropagation();
    });
});


