jQuery(document).ready(function ($) {
    const site = $('body');
    const siteBranding = $('#site-branding');
    const siteNavigation = $('#site-navigation');
    const buttonToggle = $('#button-toggle')
    const siteNavigationButton = siteNavigation.find( 'button' );
    const siteNavigationLink = siteNavigation.find( 'a' );

    // Toggle the .toggled class and the aria-expanded value each time the button is clicked.
    buttonToggle.click(function(){
        siteBranding.toggleClass('toggled');
        site.toggleClass('toggled');
        siteNavigation.toggleClass('toggled');
        if (buttonToggle.attr('aria-expanded') === 'true') {
            buttonToggle.attr('aria-expanded', 'false');
        } else {
            buttonToggle.attr('aria-expanded', 'true');
        }
    })

    siteNavigationLink.click(function (){
        siteBranding.removeClass('toggled');
        site.removeClass('toggled');
        siteNavigation.removeClass('toggled');
        buttonToggle.attr('aria-expanded', 'false');
    })

    $(window).resize(function () {
        siteBranding.removeClass('toggled');
        site.removeClass('toggled');
        siteNavigation.removeClass('toggled');
        buttonToggle.attr('aria-expanded', 'false');
    });

    $(document).on('keydown', function (e) {
        if (e.keyCode === 27) { // ESC
            siteBranding.removeClass('toggled');
            site.removeClass('toggled');
            siteNavigation.removeClass('toggled');
            buttonToggle.attr('aria-expanded', 'false');
        }
    });

    // Scroll Highlight

    const navLinks = $('.menu-main a');

    // $(document).on('click', 'a[href^="#"]', function (event) {
    //     event.preventDefault();
    //     $('html, body').animate({scrollTop: $($.attr(this, 'href')).offset().top-180}, 1000, 'easeOutExpo');  //@Todo pulir con titulares definitivos
    //     // $(this).addClass('active');
    // });

    $(function() {
        $('a[href*="#"]:not([href="#"])').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                if (target.length) {
                    $('html, body').animate({
                        scrollTop: target.offset().top-180
                    }, 1000, 'easeOutExpo');
                    return false;
                }
            }
        });
    });
    $(window).scroll(function () {
        const position = window.pageYOffset;
        $('.anchor').each(function () {
            const target = $(this).offset().top;
            const id = $(this).attr('id');
            if (position >= target - 185) { //@Todo pulir con titulares definitivos
                navLinks.removeClass('active');
                $('a.menu-link[href="#' + id + '"]').addClass('active');
            }
        });
    });
});